<template>
    <Component
        :is="currentStrapiComponent"
        :component="component" />
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object || null,
        required: true
    }
})

const componentName = computed(() => {
    if (!props.component.__component) return null
    return 'Strapi' + props.component.__component.split('.').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join('').split('-').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join('')
})

const currentStrapiComponent = computed(() => {
    if (!componentName.value) return null
    return resolveComponent(componentName.value)
})
</script>